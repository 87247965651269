// Device connect params
export const providerQueryParamName = 'provider'
export const successQueryParamName = 'success'
export const errorQueryParamName = 'error'
//
export const silenceErrorOverlayParamName = 'silenceErrorOverlay'
export const airplaneModeParamName = 'airplaneMode'
export const triggerCheckoutOnPageLoadQueryParamName = 'triggerCheckoutOnPageLoad'

export const searchQueryParamName = 'searchQueryParam'
export const nonSearchQueryParamName = 'nonSearchQueryParam'

export const circleInviteQueryParamName = 'circleInvite'
export const circleInviteConfirmationQueryParamName = 'circleInviteConfirmation'
export const referralSourceQueryParamName = 'referralSource'

export const kpiGuidanceStepQueryParamName = 'kpiGuidanceStep'

export const labNotificationQueryParamName = 'labNotification'
export const guidanceNotificationQueryParamName = 'guidanceNotification'
export const labResultsFilterQueryParamName = 'labResultsFilter'

export const showActionPlanBreadcrumbsQueryParamName = 'showActionPlanBreadcrumbs'
export const dateQueryParamName = 'date'

export const realizeAdminSpoofedUserQueryParamName = 'realizeAdminSpoofedUser'
export const tabQueryParamName = 'tab'

export const isWatchlistTabQueryParamName = 'isWatchlistTab'

export const isComingFromRecommendationsQueryParamName = 'isComingFromRecommendations'
export const conferencePromotionUrlRedirectQueryParamName = 'conferencePromotionUrlRedirect'

export const comparePackagesQueryParamName = 'comparePackages'
// auth 0
export enum auth0QueryParamNames {
  code = 'code',
  state = 'state',
  message = 'message',
  success = 'success',
  supportForgotPassword = 'supportForgotPassword',
  supportSignUp = 'supportSignUp',
}

export const packageListTypeQueryParamName = 'packageListType'

export const slugQueryParamName = 'slug'

export const promoCodeQueryParamName = 'promoCode'

// franchise page
export const franchiseLocationQueryParamName = 'franchiseLocation'
export const postAuthFormStateQueryParamName = 'postAuthFormState'
export const franchiseServiceQueryParamName = 'franchiseService'
