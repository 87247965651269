export const profileFormId = 'profile-form'
export const profileEditButtonId = 'profile-edit-button'
export const profileBirthdayInputId = 'profile-birthday-input'

export const personalDetailsSectionId = 'personal-details-section'
export const achievementsSectionId = 'achievements-section'
export const homeTilesSectionId = 'home-tiles-section'
export const unitsSectionId = 'units-section'
export const metricUnitPreferencesSectionId = 'metric-unit-preferences-section'
export const qualitativeMetricsSectionId = 'qualitative-metrics-section'

// Create A Metric ids
export const createMetricButtonId = 'create-metric-button'
export const createMetricModalId = 'create-metric-modal'
export const userMetricModalSubmitButtonId = 'user-metric-modal-submit-button'

// Supplement Stack ids
export const addSupplementButtonId = 'add-supplement-button'
export const addSupplementModalId = 'add-supplement-modal'

// Set Metric Target ids
export const addTargetButtonId = 'add-target-button'
export const searchMetricModalId = 'search-metric-modal'
